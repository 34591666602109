import React from 'react';

const OthersVehicleData = ({
  brands,
  register,
  selectedBrand,
  setSelectedBrand,
  BranchId,
}) => {
  return (
    <>
      <div className="card__new-client-row" style={{ marginTop: 15 }}>
        <div className="card__new-client-column">
          <span className="card__new-client-span">
            Objeto de Manutenção: <span style={{ color: 'red' }}>*</span>
          </span>

          <input
            type="text"
            className="form-control foco-input"
            style={{ width: '17rem' }}
            {...register('Maintenance_Object')}
          />
        </div>

        <div className="card__new-client-column">
          <span className="card__new-client-span">
            Marca: <span style={{ color: 'red' }}>*</span>
          </span>
          <select
            name="Brand"
            className="form-control foco-input"
            style={{
              width: '23rem',
            }}
            value={selectedBrand}
            onChange={(e) => setSelectedBrand(e.target.value)}
          >
            <option value="">Selecione</option>
            {brands.map((b) => (
              <option value={b.Brand} key={b.Brand}>
                {b.Brand}
              </option>
            ))}
          </select>
        </div>

        <div className="card__new-client-column">
          <span className="card__new-client-span">
            Modelo: <span style={{ color: 'red' }}>*</span>
          </span>

          <input
            type="text"
            className="form-control foco-input"
            style={{ width: '25rem' }}
            {...register('Model')}
          />
        </div>
      </div>

      <div className="card__new-client-row" style={{ marginTop: 15 }}>
        <div className="card__new-client-column">
          <div className="flex row" style={{ marginLeft: '0px' }}>
            <span className="card__new-client-span">Ano Fab.: </span>
            {BranchId !== 5 && <span style={{ color: 'red' }}>*</span>}
          </div>
          <input
            onInput={(e) => (e.target.value = e.target.value.slice(0, 4))}
            type="number"
            className="form-control foco-input"
            style={{ width: '8rem' }}
            {...register('Year_Manufacture')}
          />
        </div>

        <div className="card__new-client-column">
          <div className="flex row" style={{ marginLeft: '0px' }}>
            <span className="card__new-client-span">Ano Mod.: </span>
            {BranchId !== 5 && <span style={{ color: 'red' }}>*</span>}
          </div>
          <input
            onInput={(e) => (e.target.value = e.target.value.slice(0, 4))}
            type="number"
            className="form-control foco-input"
            style={{ width: '8rem' }}
            {...register('Year_Model')}
          />
        </div>

        <div className="card__new-client-column">
          <div className="flex row" style={{ marginLeft: '0px' }}>
            <span className="card__new-client-span">KM: </span>
            {BranchId !== 5 && <span style={{ color: 'red' }}>*</span>}
          </div>
          <input
            onInput={(e) => (e.target.value = e.target.value.slice(0, 6))}
            type="number"
            className="form-control foco-input"
            style={{ width: '8rem' }}
            {...register('Kilometers')}
          />
        </div>

        <div className="card__new-client-column">
          <span className="card__new-client-span">Cor:</span>
          <input
            type="text"
            className="form-control foco-input"
            style={{ width: '10rem' }}
            {...register('Color')}
          />
        </div>

        <div className="card__new-client-column">
          <span className="card__new-client-span">Chassi/Série:</span>
          <input
            type="text"
            className="form-control foco-input"
            style={{ width: '25rem' }}
            {...register('NChassi')}
          />
        </div>

        <div className="card__new-client-column">
          <span className="card__new-client-span">Tipo de Combustível:</span>
          <select
            className="form-control foco-input"
            style={{
              width: '15rem',
            }}
            {...register('Fuel_Type')}
          >
            <option value="">Selecione</option>
            <option value="Álcool">Álcool</option>
            <option value="Diesel">Diesel</option>
            <option value="Elétrico">Elétrico</option>
            <option value="Gás">Gás</option>
            <option value="Gasolina">Gasolina</option>
            <option value="Gasolina/Álcool">Gasolina/Álcool</option>
            <option value="Gasolina/Álcool/Gás">Gasolina/Álcool/Gás</option>
            <option value="Gasolina/Gás">Gasolina/Gás</option>
            <option value="Gás/Álcool">Gás/Álcool</option>
            <option value="Gasolina/Elétrico">
              Híbrido (Gasolina/Elétrico)
            </option>
            <option value="Outro">Outro</option>
          </select>
        </div>
      </div>
    </>
  );
};

export default OthersVehicleData;
