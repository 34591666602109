import React, { useEffect, useState, useMemo } from 'react';
import { toastr } from 'react-redux-toastr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { differenceInDays, format, isValid, parseISO } from 'date-fns';
import { faDownload, faReceipt } from '@fortawesome/free-solid-svg-icons';

import {
  Card,
  CheckboxesGrid,
  CheckboxLabel,
  Content,
  ContentWrapper,
  DontCloseAdvice,
  ExportButton,
  ExportTimeAdvice,
  Header,
  HeaderItem,
  HeaderLogo,
  InvoiceTotalizatorsList,
  InvoiceType,
  InvoiceTypeDescription,
  PageTitle,
  Separator,
  Span,
  Table,
  TableContent,
  TableHeader,
  TableRow,
  TotalizatorText,
  Wrapper,
} from './ExportXML.styles';

import { ProtocolDownloadButton } from './components/ProtocolDownloadButton';

import { InputContainer } from 'v2/components/Input';
import { InputText } from 'v2/components/Input';
import { currentBrandingName } from 'v2/helpers/brandingHelpers';
import { ExportXMLRepository } from 'v2/repositories/ExportXML';

import { useExportXMLStore } from 'v2/store/exportXMLStore';
import { handleBeforeUnload } from 'v2/utils/handleBeforeUnload';

import { useAuth } from 'contexts/auth';
import addressRepository from 'repositories/Addresses';
import { useThemeContext } from 'v2/contexts/themeContext';

export function ExportXML() {
  const { companyId } = useAuth();
  const { getBrandingLogo, setDocumentTitle, brandingId } = useThemeContext();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    exportProtocols,
    cleanDownloadedProtocols,
    setExportProtocols,
    initialDateExport,
    finalDateExport,
    setInitialDateExport,
    setFinalDateExport,
  } = useExportXMLStore();

  const [selectedInvoiceTypes, setSelectedInvoiceTypes] = useState({
    NFe: false,
    NFSe: false,
    NFCe: false,
    SAT: false,
  });

  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');

  const [address, setAddress] = useState({});

  const noInvoiceSelected = useMemo(
    () => !Object.values(selectedInvoiceTypes).some((value) => value === true),
    [selectedInvoiceTypes]
  );

  const validPeriod = useMemo(() => {
    return initialDate && finalDate && initialDate <= finalDate;
  }, [initialDate, finalDate]);

  const handleChangeSelectedInvoiceTypes = (invoiceType) => {
    setSelectedInvoiceTypes({
      ...selectedInvoiceTypes,
      [invoiceType]: !selectedInvoiceTypes[invoiceType],
    });
  };

  const handleSubmit = async () => {
    const today = new Date();
    const thirtyOneDaysAgo = new Date();
    thirtyOneDaysAgo.setDate(today.getDate() - 31);

    const invalidPeriod =
      differenceInDays(new Date(finalDate), new Date(initialDate)) > 31;

    if (invalidPeriod) {
      return toastr.error(
        'Não foi possível exportar',
        'O período informado deve ser inferior a 31 dias'
      );
    }

    setIsSubmitting(true);

    const serializedDocumentTypes = Object.entries(selectedInvoiceTypes)
      .filter((documentType) => documentType[1] === true)
      .map((invoiceType) => invoiceType[0]);

    try {
      const res = await ExportXMLRepository.generateExport({
        initialDate,
        finalDate,
        companyId,
        documentType: serializedDocumentTypes,
      });

      setInitialDateExport(initialDate);
      setFinalDateExport(finalDate);

      setExportProtocols(
        res.data.map((protocol) => ({
          ...protocol,
          ready: false,
          downloaded: false,
        }))
      );
    } catch (err) {
      const message =
        err.response.data.message ===
        'No invoices were found within the period informed.'
          ? 'Não foi identificada nenhuma nota fiscal emitida e/ou cancelada. Verifique o período e tente novamente'
          : 'Por favor, tente novamente';

      toastr.error('Nenhuma exportação foi realizada', message);
      console.error(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const getCompanyAddress = async () => {
    try {
      const { data: companyAddress } =
        await addressRepository.getCompanyAddress(companyId);

      setAddress(companyAddress?.data[0]);
    } catch (err) {
      toastr.error(
        'Falha ao buscar endereço',
        'Ocorreu um erro ao buscar o endereço da empresa. Tente novamente!'
      );
    }
  };

  const getNotIssuedInvoiceLink = (type) => {
    switch (true) {
      case type === 'NF-e' || type === 'NFC-e':
        return (
          window.location.origin +
          `/client/invoices?status=Aberta,Aberta%20c/%20Erro&initialDate=${initialDateExport}&finalDate=${finalDateExport}`
        );
      case type === 'NFS-e':
        return (
          window.location.origin +
          `/client/service-invoices?status=Aberta,Aberta%20c/%20Erro&initialDate=${initialDateExport}&finalDate=${finalDateExport}`
        );
      default:
        return '#';
    }
  };

  useEffect(() => {
    if (companyId) getCompanyAddress();
  }, [companyId]);

  useEffect(handleBeforeUnload, []);

  useEffect(() => {
    if (brandingId) {
      setDocumentTitle('Exportação de XML');
    }
  }, [brandingId]);

  useEffect(() => {
    cleanDownloadedProtocols();
  }, []);

  return (
    <Wrapper>
      <Header>
        <HeaderLogo src={getBrandingLogo('horizontal-white')} />
      </Header>

      <ContentWrapper>
        <Content>
          <PageTitle>
            <FontAwesomeIcon icon={faReceipt} />
            Exportação de XML
          </PageTitle>
          <Card>
            <Span>
              Selecione o tipo de nota fiscal e período de exportação dos XML's:
            </Span>

            <div className="flex center column" gap-050>
              <CheckboxesGrid>
                <div className="flex column gap-050">
                  <div className="flex align-center gap-050">
                    <input
                      key={'checkbox__nfe'}
                      type="checkbox"
                      value={selectedInvoiceTypes.NFe}
                      onChange={() => handleChangeSelectedInvoiceTypes('NFe')}
                    />
                    <CheckboxLabel>
                      <strong>NF-e</strong> (Nota Fiscal Eletrônica)
                    </CheckboxLabel>
                  </div>
                  <div className="flex align-center gap-050">
                    <input
                      key={'checkbox__nfce'}
                      type="checkbox"
                      value={selectedInvoiceTypes.NFCe}
                      onChange={() => handleChangeSelectedInvoiceTypes('NFCe')}
                    />
                    <CheckboxLabel>
                      <strong>NFC-e</strong> (Nota Fiscal do Consumidor
                      Eletrônica)
                    </CheckboxLabel>
                  </div>
                </div>
                <div className="flex column gap-050">
                  <div className="flex align-center gap-050">
                    <input
                      key={'checkbox__nfse'}
                      type="checkbox"
                      value={selectedInvoiceTypes.NFSe}
                      onChange={() => handleChangeSelectedInvoiceTypes('NFSe')}
                    />
                    <CheckboxLabel>
                      <strong>NFS-e</strong> (Nota Fiscal de Serviço Eletrônica)
                    </CheckboxLabel>
                  </div>
                  <div className="flex align-center gap-050">
                    <input
                      key={'checkbox__sat'}
                      type="checkbox"
                      value={selectedInvoiceTypes.SAT}
                      disabled={address?.State !== 'SP'}
                      onChange={() => handleChangeSelectedInvoiceTypes('SAT')}
                    />
                    <CheckboxLabel>
                      <strong>SAT CF-e</strong> (Cupom Fiscal Eletrônico -
                      Exclusivo SP)
                    </CheckboxLabel>
                  </div>
                </div>
              </CheckboxesGrid>

              <div className="flex row gap-050">
                <InputContainer>
                  <InputText
                    type="date"
                    value={initialDate}
                    onChange={(e) => setInitialDate(e.target.value)}
                  />
                </InputContainer>
                <InputContainer>
                  <InputText
                    type="date"
                    value={finalDate}
                    onChange={(e) => setFinalDate(e.target.value)}
                  />
                </InputContainer>
              </div>

              <ExportButton
                className="button button-green flex row align-center gap-075 mt-2"
                disabled={noInvoiceSelected || !validPeriod || isSubmitting}
                onClick={handleSubmit}
              >
                <span
                  className={
                    isSubmitting
                      ? 'fa fa-spinner fa-pulse fa-1x update-spinner'
                      : 'update-spinner-disabled'
                  }
                />
                Processar Exportação <FontAwesomeIcon icon={faDownload} />
              </ExportButton>

              <ExportTimeAdvice>
                Devido ao volume de notas, a exportação poderá levar cerca de
                5-7 minutos de processamento. Após o pedido, clique no botão
                "Consultar" para verificar se seu pedido foi processado.
              </ExportTimeAdvice>
            </div>
          </Card>

          <Separator />

          {exportProtocols.length > 0 && (
            <>
              <div className="flex justify-between align-center">
                <div className="flew gap-075">
                  {isValid(new Date(initialDateExport)) &&
                  isValid(new Date(finalDateExport)) ? (
                    <>
                      <strong>Período de Exportação: </strong>
                      <span>
                        {format(parseISO(initialDateExport), 'dd/MM/yyyy')} -{' '}
                        {format(parseISO(finalDateExport), 'dd/MM/yyyy')}
                      </span>
                    </>
                  ) : (
                    <div />
                  )}
                </div>
                <DontCloseAdvice>
                  Não feche a tela durante o processamento.
                </DontCloseAdvice>
              </div>
              <Card>
                <Table>
                  <TableHeader>
                    <HeaderItem>Documento Fiscal</HeaderItem>
                    <HeaderItem>Quantidade</HeaderItem>
                    <HeaderItem>Total</HeaderItem>
                    <HeaderItem style={{ textAlign: 'center' }}>
                      Ações
                    </HeaderItem>
                  </TableHeader>
                  <Separator style={{ margin: 0 }} />
                </Table>
                <TableContent>
                  {exportProtocols.map((protocol) => {
                    return (
                      <TableRow key={protocol.type}>
                        <div className="flex column justify-center align-start">
                          <InvoiceType>{protocol.type}</InvoiceType>
                          <InvoiceTypeDescription>
                            {protocol.description}
                          </InvoiceTypeDescription>
                        </div>
                        <div className="flex column justify-center align-start">
                          <InvoiceTotalizatorsList>
                            {protocol.quantity?.map((invoiceQuantity) => (
                              <li key={invoiceQuantity.description}>
                                {invoiceQuantity.description ===
                                'Não emitidas' ? (
                                  <a
                                    href={getNotIssuedInvoiceLink(
                                      protocol.type
                                    )}
                                    style={{ cursor: 'pointer' }}
                                    className="text-red underline"
                                    target="_blank"
                                  >
                                    Não Emitidas:{' '}
                                    <strong>{invoiceQuantity.quantity}</strong>
                                  </a>
                                ) : (
                                  <>
                                    {invoiceQuantity.description}:{' '}
                                    <strong>{invoiceQuantity.quantity}</strong>
                                  </>
                                )}
                              </li>
                            ))}
                          </InvoiceTotalizatorsList>
                        </div>

                        <div className="flex align-center">
                          <TotalizatorText>
                            {protocol.total} XML
                          </TotalizatorText>
                        </div>

                        <div className="flex column center">
                          {protocol.total > 0 && (
                            <ProtocolDownloadButton
                              key={`button_protocol-${protocol.type}`}
                              protocol={protocol}
                            />
                          )}
                        </div>
                      </TableRow>
                    );
                  })}
                </TableContent>
              </Card>
            </>
          )}
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
}
